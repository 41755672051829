<template>
  <div class="hello">
    <h4>Sistema de Audiencias</h4>
    <p></p>
    <img class="img-fluid" src="../assets/inicio.jpg" />
  </div>
</template>

<script>
export default {
  name: "Inicio",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
